@import './SideMenu.scss';

.navbar {
  position: fixed;
  // bottom: 0;
  top: 0;
  left: 0;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 200;
  display: flex;

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      width: 65px;
    }
  }

  flex-direction: column;
  ul {
    display: flex;
  }

  background-color: white;
  box-shadow: 0px 0px 20px 1px rgb(0 0 0 / 31%);
  .navbar-desktop {
    display: none;
  }
}

@media screen and (min-width: 1025px) {
  .navbar-mobile {
    display: none !important;
  }
  .navbar {
    top: 0;
    bottom: unset;
    height: 150px;
    .navbar-desktop {
      display: unset;
    }

    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      h4 {
        font-size: 20px;
        font-weight: 500;
        font-family: 'Courier Prime', monospace;
      }
      .logo {
        width: 90px;
        margin-right: 50px;
      }
    }

    ul {
      padding: 5px;
      li {
        padding: 5px 10px;
        &:hover {
          border-bottom: 1px solid black;
        }
      }
    }

    .selected li {
      border-bottom: 1px solid black;
    }
  }
}
