.section-image {
  width: 100%;
  max-height: 90vh;
  object-fit: cover;
}

.about {
  margin-top: 70px;
  h1 {
    margin: 50px 0;
  }
  div {
    width: 90%;
    margin: 50px auto;
    max-width: 1000px;
  }
  p {
    margin: 20px 0;
    line-height: 28px;
    text-align: left;
  }
}

.section-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  div {
    width: 90%;
    margin: 0 auto;
  }
  .vertical-line {
    width: 90%;
    margin: 20px 0;
    border-bottom: 1px solid #333;
  }
  .hours-container {
    margin-top: 50px;
  }
  h1 {
    margin-top: 0;
    font-weight: 600;
  }
}

@media screen and (min-width: 600px) {
  .section-row {
    div {
      width: 45%;
    }
    .vertical-line {
      border-right: 1px solid #333;
      width: 2px !important;
      border-bottom: none;
      margin: 0;
    }
    .hours-container {
      margin-top: 0;
    }
  }
}
