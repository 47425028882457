@import './reset.css';
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');

// @font-face {
//   font-family: Hybi;
//   src: url('./assets/fonts/Hybi11.Amigo/Hybi11\ Amigo\ Regular.ttf');
// }

h1 {
  font-weight: 600;
}

h2 {
  font-size: 20px;
  font-weight: 600;
}

h4 {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
}

p {
  font-size: 15px;
  line-height: 25px;
}

.modal {
  div {
    // overflow: auto;
    // max-height: 90vh;

    .MuiGrid-root {
      overflow: auto;
      max-height: 90vh;
      padding: 20px 0;
    }
  }
}

.front-modal {
  max-width: 600px;
  margin: 0 auto;
}

.loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .loader-wrapper {
    position: relative;
    display: flex;
    width: 200px;
    height: 200px;
    img {
      z-index: 999;
    }
    justify-content: center;
    align-items: center;
  }
}

.App {
  text-align: center;
  position: relative;
  /* font-family: 'Courier Prime', monospace; */
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: #333;
}

section {
  margin: 50px 0;
  h1 {
    margin: 20px 0;
  }
  p {
    line-height: 30px;
  }
}

.mobile-only {
  display: none;
}

.main {
  min-height: calc(100vh - 70px);
  /* max-width: 1440px; */
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  .main-spacer {
    flex-grow: 1;
  }
  // margin-bottom: 130px;
}
.main-content {
  margin: 0 auto;
  width: 100%;
  // max-width: 1300px;
}
@media screen and (min-width: 1025px) {
  .main {
    min-height: calc(100vh - 150px);
    position: absolute;
    margin-top: 150px;
    width: 100%;
  }
  section {
    margin: 120px 0;
    h1 {
      margin: 35px 0;
    }
    p {
      line-height: 30px;
    }
  }
  .mobile-only {
    display: unset;
  }
}

.lds-ripple {
  display: inline-block;
  position: absolute;
  top: -4px;
  left: -4px;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #ca274f;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 100px;
    left: 100px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 200px;
    height: 200px;
    opacity: 0;
  }
}
