.pieces-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  a {
    margin-bottom: 40px;
    h4 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }
  .piece-preview {
    padding: 5px;
    border: 5px solid rgb(69, 64, 64);
    margin: 5px 10px;
    // width: 90%;
    object-fit: contain;
    width: 80%;
    height: unset;
    max-height: unset;
  }
}

.fliter-container {
  margin: 50px auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  //   max-width: 600px;
  text-align: left;
}

.filter-chips {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  p {
    border: 1px solid black;
    border-radius: 75px;
    display: flex;
    padding: 5px 20px;
    margin: 10px 5px;
    flex-direction: row;
    justify-content: center;
    font-size: 15px;
    color: white;
    background-color: #333;
    box-shadow: 1px 1px 5px 0px #00000066;
  }
}

@media screen and (min-width: 600px) {
  .fliter-container {
    width: 100%;
    display: flex;
    justify-content: center;
    // justify-content: flex-start;
    div {
      margin-right: 10px;
    }
  }

  .pieces-container {
    .piece-preview {
      max-height: 300px;
      width: unset;
    }
  }
}
