@import '~react-image-gallery/styles/scss/image-gallery.scss';

.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: unset;
}

.artwork-container {
  display: flex;
  // flex-wrap: wrap;
  max-width: 1300px;
  margin: 50px auto;
  flex-direction: column;
  justify-content: space-between;
  .image-gallery-content {
    width: 100%;
    max-height: 80vh;
  }
  .image-gallery-slide-wrapper {
    max-height: 80vh;
  }
  .image-gallery-image {
    border: 4px solid #333;
    padding: 4px;
    max-width: calc(100% - 16px);
    max-height: calc(80vh - 4px);
  }

  .image-gallery-content.left .image-gallery-slide .image-gallery-image,
  .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: calc(80vh - 4px);
  }
  .details {
    width: 90%;
    margin: 50px auto;
    text-align: left;
    .details-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h2 {
        margin-bottom: 20px;
        font-size: 40px;
      }
      h3 {
        font-size: 25px;
        margin-bottom: 15px;
      }
      .description {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .work-gallery,
  .image-gallery-using-mouse {
    width: 45%;
    max-width: 500px;
    max-height: 600px;
    margin: 20px 0 20px auto;
    padding: 20px 0;
    // border: 2px solid #333;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.26) inset;
    border-radius: 5px;
    // max-height: 500px !important;
  }
  .artwork-container {
    flex-direction: row;
    .details {
      max-width: 50%;
      // max-width: 400px;
      .details-text {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
  .image-gallery-content.right.fullscreen,
  .image-gallery-content.right.fullscreen .image-gallery-slide-wrapper,
  .image-gallery-content.right.fullscreen
    .image-gallery-slide-wrapper
    .image-gallery-image {
    // max-height: unset !important;
    // height: 100vh;
  }
  .image-gallery-image {
    max-height: calc(500px - 4px) !important;
  }
}
