.expo-single-container {
  max-width: 1300px;
  margin: 0 auto;
  .expo-header {
    img {
      height: auto;
      width: 90%;
      margin: 0 auto;
      object-fit: cover;
      max-height: 46vw;
    }
  }

  .content {
    .row {
      display: flex;
      align-content: center;
      margin: 20px 0;
    }
    h1 {
      font-size: 25px;
    }
    width: calc(90% - 40px);
    margin: 0px auto;
    text-align: left;
    background-color: rgba(211, 211, 211, 0.41);
    padding: 50px 20px;
  }
}

@media screen and (min-width: '600px') {
  .expo-single-container {
    .content {
      width: calc(90% - 80px);
      padding: 50px 40px;
      h1 {
        font-size: unset;
      }
    }
  }
}
