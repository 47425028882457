.contact {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  max-width: 1300px;
  margin: 50px auto;
  .contact-text {
    margin: 50px 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    h1 {
      margin-bottom: 20px;
    }
    .email {
      margin-top: 30px;
    }
  }
}

@media screen and (min-width: 600px) {
  .contact {
    flex-direction: row;
    .form {
      width: 50%;
      max-width: 500px;
    }
    // div {
    //   width: 90%;
    // }
  }
}
