.artists-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.artist-container {
  padding: 0px;
  overflow: hidden;
  margin: 20px auto;
  max-height: 400px;
  max-width: 90%;
  min-height: 400px;
  border-radius: 15px;
  box-shadow: 0 10px 50px -9px rgb(0 0 0 / 50%);
  display: flex;
  justify-content: flex-start;
  position: relative;
  .preview-hover {
    position: absolute;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0;
    transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    // font-size: ;
    &:hover {
      opacity: 1;
    }
  }
  .bio-photo {
    width: 100%;
    height: 75%;
    object-fit: cover;
  }
  .profile-photo {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
    padding: 1px;
    border: 2px solid #c5778a;
  }
  .artist-preview-header {
    display: flex;
    height: 20%;
    padding-left: 20px;
    margin-top: 20px;

    // justify-content: center;
    // align-items: center;
  }
  .text-container {
    // height: 30%;
    display: flex;
    margin-left: 20px;
    text-align: left;
    flex-direction: column;

    // justify-content: center;
    // h2 {
    //   margin-bottom: 5px;
    // }
  }
}

@media screen and (min-width: 600px) {
  .artist-container {
    max-width: 45%;
    margin: 20px 2.5%;
  }
}

@media screen and (min-width: 1024px) {
  .artist-container {
    max-width: 29%;
    margin: 20px 2%;
  }
}

@media screen and (min-width: 1200px) {
  .artist-container {
    max-width: 23%;
    margin: 20px 1%;
  }
}
