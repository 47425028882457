/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 25px;
  left: 25px;
  top: 22px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #333;
  border-radius: 20px;
  height: 14% !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #333;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 36px !important;
  width: 36px !important;
  top: 20px !important;
  left: 20px !important;
  // top: unset !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #333;
  height: 30px !important;
}

/*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
.bm-menu-wrap {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  height: 100vh !important;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  padding-top: 10vh;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  .selected {
    border: 1px solid black !important;
  }
  li {
    padding: 10px !important;
  }
  color: #333;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin: 25px 0;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}
