.expos-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1440px;
  margin: 0 auto;
}

.expo-container {
  padding: 0px;
  overflow: hidden;
  margin: 20px auto;
  max-height: 300px;
  max-width: 90%;
  min-height: 270px;
  border-radius: 15px;
  box-shadow: 0 10px 50px -9px rgb(0 0 0 / 50%);
  display: flex;
  justify-content: flex-start;
  position: relative;
  .preview-hover {
    position: absolute;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0;
    transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    // font-size: ;
    &:hover {
      opacity: 1;
    }
  }
  .bio-photo {
    width: 100%;
    height: 73%;
    object-fit: cover;
  }
  .profile-photo {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
    padding: 1px;
    border: 2px solid #c5778a;
  }
  .expo-preview-header {
    display: flex;
    height: 20%;
    flex-direction: column;
    justify-content: center;
    .row {
      display: flex;
    }

    // padding-left: 20px;
    // margin-top: 20px;

    // justify-content: center;
    // align-items: center;
  }
  .text-container {
    // height: 30%;
    display: flex;
    margin-left: 20px;
    text-align: left;
    flex-direction: column;

    // justify-content: center;
    // h2 {
    //   margin-bottom: 5px;
    // }
  }
}

@media screen and (min-width: 600px) {
  .expo-container {
    max-width: 900px;
    max-height: 540px;
    margin: 20px 2.5%;
    width: 100%;
    .bio-photo {
      height: 85%;
    }
    .expo-preview-header {
      height: 14%;
    }
  }
}

// @media screen and (min-width: 1024px) {
//   .expo-container {
//     max-width: 35%;
//     margin: 20px 2%;
//   }
// }

// @media screen and (min-width: 1200px) {
//   .expo-container {
//     max-width: 30%;
//     margin: 20px 1%;
//   }
// }
