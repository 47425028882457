.artist-gallery {
  width: 98%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  a {
    max-height: 200px;
    margin: 2.5px -3px;

    img {
      // height: 200px;
      width: 100%;
      // max-height: 300px;
      height: 100%;
      object-fit: cover;
    }
  }
}

.artist-header {
  position: relative;
  img {
    filter: grayscale(100%) brightness(0.85);
    max-height: 700px;
    object-fit: cover;
  }

  .artist-header-text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    text-shadow: 2px 2px 2px #0000008c;

    h3 {
      color: white;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 600;
    }
    .artist-name {
      //   margin-top: 200px;
      color: white;
      font-weight: 600;
      font-size: 40px;
    }
  }
}

.artist-bio-container {
  // display: flex;
  // flex-direction: column;
  width: 90%;

  max-width: 1000px;

  margin: 50px auto;

  p {
    // text-align: left;
    // width: 90%;
    // max-width: 700px;
    // margin: 0 auto;
    // margin-bottom: 50px;

    // padding-right: 50px;
  }
}

@media screen and (min-width: 600px) {
  .artist-gallery {
    // margin: 0 20px;
    a {
      width: 33.3333%;
      padding: 0 3px;
      margin-bottom: 3px;
      img {
        max-width: 98.5%;
      }
    }
  }
  .artist-bio-container {
    // flex-direction: row;
    // img {
    //   max-width: 400px;
    // }
  }
  .artist-header-text {
    h3 {
      font-size: 30px !important;
    }
    .artist-name {
      font-size: 53px !important;
    }
  }
}

@media screen and (min-width: 1024px) {
  .artist-gallery {
    // margin: 0 20px;
    a {
      width: 25%;
      img {
        max-width: 98.5%;
      }
    }
  }
}
